import { PlainDocument, preload } from '../../../../layouts/plain-document';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function TermsOfService(props: RouteSectionProps) {
	return (
		<PlainDocument
			slug={`${props.params.cardType}-terms-and-conditions`}
			breadcrumbs={[
				{ href: '/rewards', title: 'Troon Rewards' },
				{ href: '/rewards/visa', title: 'Visa Benefits' },
			]}
		/>
	);
}

export const route = {
	preload: ({ params }) => preload(`${params.cardType}-terms-and-conditions`),
} satisfies RouteDefinition;
