import { Navigate, useParams } from '@solidjs/router';
import { ActivityIndicator, Button, Container, Heading, Link, Page, Section } from '@troon/ui';
import { Show, Suspense, useContext } from 'solid-js';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { IconUserAdd } from '@troon/icons/user-add';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { ReservationCtx } from '../../../providers/reservation';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { useUser } from '../../../providers/user';
import { ReservationState } from '../../../graphql';
import { ReservationAccessUpsell } from '../../../components/upsells/access-reservation';
import { InvitePlayers } from './components/invite-players';
import { InvitePlayersInfo } from './components/invite-players-info';

export default function ReservationConfirmation() {
	const params = useParams<{ reservationId: string }>();
	const data = useContext(ReservationCtx)!;
	const user = useUser();

	return (
		<Suspense fallback={<ActivityIndicator class="size-16 text-brand">Confirming your Reservation</ActivityIndicator>}>
			<Show
				when={
					!!(
						data()?.reservation.ownerId === user()?.me.id &&
						data()?.reservation.state !== ReservationState.Cancelled &&
						!data()?.reservation.isPastStartTime &&
						(data()?.reservation.playerCount ?? 0) > 1
					)
				}
				fallback={<Navigate href={`/reservation/${params.reservationId}`} />}
			>
				<Container size="small">
					<Page class="gap-8 md:gap-12">
						<Section>
							<IconCircleCheckCustom class="size-24 md:size-32" />
							<Heading as="h1">Your tee time is confirmed!</Heading>
							<Suspense>
								<Show when={(data()?.reservation.playerCount ?? 0) > 1}>
									<InvitePlayersInfo />
								</Show>
							</Suspense>
						</Section>

						<Suspense>
							<Section layout="tight" class="rounded border border-neutral p-4 md:p-6">
								<Heading as="h2" size="h4" class="flex items-center gap-2">
									<IconUserAdd class="text-brand" />
									<span>Invite Players</span>
								</Heading>

								<InvitePlayers showRecentInvited />
							</Section>
						</Suspense>

						<Suspense>
							<Show when={data()?.accessUpsell}>{(upsell) => <ReservationAccessUpsell upsell={upsell()} />}</Show>
						</Suspense>

						<Section class="items-center text-center">
							<Button as={Link} href={`/reservation/${params.reservationId}`} appearance="secondary">
								Continue to Reservation <IconArrowRightMd />
							</Button>
						</Section>

						<Suspense>
							<Show
								when={
									(data()?.reservation.playerCount ?? 0) > 1 && !user()?.activeTroonCardSubscription?.unlimitedGuestPass
								}
							>
								<Section>
									<Heading as="h2" size="h3">
										Frequently Asked Questions
									</Heading>
									<div class="rounded border border-neutral">
										<FrequentlyAskedQuestions id="invite-users-faq" />
									</div>
								</Section>
							</Show>
						</Suspense>
					</Page>
				</Container>
			</Show>
		</Suspense>
	);
}

export const route = { info: { nav: { hero: false } } };
