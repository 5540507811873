import { Title } from '@solidjs/meta';
import { Heading, Container, Page, BreadcrumbItem, Section, Button, Link, HorizontalRule, Picture } from '@troon/ui';
import { createMemo, For, Show, Suspense } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync, useLocation } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { createContentfulListRequest } from '../../content/[model]/_client';
import { PictureSection } from '../../../components/layouts/picture-section';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition } from '@solidjs/router';

const tz = 'America/Phoenix';
export default function DEIPage() {
	const loc = useLocation();

	const themes = createAsync(
		async () => {
			const themes = await getThemes({});
			return themes.sort((a, b) =>
				dayjs(`${a.fields.startMonth} ${a.fields.startDay}, 2024`).isAfter(
					dayjs(`${b.fields.startMonth} ${b.fields.startDay}, 2024`),
				)
					? 1
					: -1,
			);
		},
		{ deferStream: true },
	);
	const theme = createMemo(() => {
		const today = dayjs.tz(new Date(), tz);
		return themes()?.find((theme) => {
			const start = dayjs.tz(`${theme.fields.startMonth} ${theme.fields.startDay}, ${today.year()}`, tz);
			const end = dayjs.tz(`${theme.fields.endMonth} ${theme.fields.endDay}, ${today.year()}`, tz);
			return today.isSameOrAfter(start) && today.isSameOrBefore(end);
		});
	});

	return (
		<>
			<Title>Diversity, Equity, and Inclusion | Troon</Title>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/web/hero/dei.jpg`}
				crop="top"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>DEI</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					Diversity, Equity, and Inclusion
				</Heading>
			</Hero>

			<NoHydration>
				<Page>
					<Show when={theme()}>
						{(theme) => (
							<Container>
								<Suspense>
									<PictureSection src={theme().fields.heroImage?.fields.file?.url}>
										<div>
											<Heading as="h2">{theme().fields.title}</Heading>
											<p class="font-medium text-brand-500">
												{theme().fields.startMonth}
												<Show when={theme().fields.startMonth !== theme().fields.endMonth}>
													{' '}
													{theme().fields.startDay} – {theme().fields.endMonth} {theme().fields.endDay}
												</Show>
											</p>
										</div>
										<Show when={theme().fields.description}>{(doc) => <RichText document={doc()} />}</Show>
										<Button as={Link} href={`/about/dei/${theme().fields.slug}`} class="size-fit grow-0">
											Read more<span class="sr-only"> about {theme().fields.title}</span>
											<IconArrowRightMd />
										</Button>
									</PictureSection>
								</Suspense>
							</Container>
						)}
					</Show>

					<Container size="small">
						<Section appearance="primary" class="items-center rounded px-4 text-center md:px-16">
							<Heading as="h2" size="h6" class="text-base uppercase">
								Mission statement
							</Heading>
							<p class="max-w-3xl text-xl md:text-2xl">
								At Troon, we are <b>committed to fostering</b> a culture of diversity, equity, and inclusion that
								celebrates the unique qualities <b>within</b> our communities, <b>including</b> race, gender, age,
								religion, identity, cultures, ideas, and experiences. <b>We strive</b> to create workplaces and
								recreational spaces where every individual feels welcomed, respected, and valued.
							</p>

							<p class="max-w-3xl text-xl md:text-2xl">
								Promoting diversity, equity, and inclusion{' '}
								<b>is not only the right thing to do but also a cornerstone of strong business practices</b>. A diverse
								range of perspectives and talents enhances our <b>organization</b> by
								<b>cultivating</b> a more inclusive and collaborative environment, attracting <b>the best</b> talent,
								and <b>offering diverse viewpoints to tackle challenges faced by</b> our company, clients, and
								associates.
							</p>
						</Section>
					</Container>

					<Container>
						<Page>
							<Section>
								<Heading as="h2" size="h3">
									Troon Honors The Diversity Of Our Associates Worldwide
								</Heading>
								<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
									<For each={themes()}>
										{(theme) => (
											<article class="group relative flex flex-col gap-2">
												<div class="overflow-hidden rounded">
													<Picture
														preload={false}
														src={theme.fields.heroImage?.fields.file?.url}
														alt={
															theme.fields.heroImage?.fields.description ?? theme.fields.heroImage?.fields.title ?? ''
														}
														width={480}
														height={270}
														sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 95vw"
														class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
														crop="face"
														loading="lazy"
													/>
												</div>
												<Link
													href={`/about/dei/${theme.fields.slug}`}
													class="block text-lg font-semibold after:absolute after:inset-0"
												>
													{theme.fields.title}
												</Link>
												<p class="text-sm font-medium text-brand-500">
													{theme.fields.startMonth}
													<Show when={theme.fields.startMonth !== theme.fields.endMonth}>
														{' '}
														{theme.fields.startDay} – {theme.fields.endMonth} {theme.fields.endDay}
													</Show>
												</p>
												<Show when={theme.fields.description}>{(doc) => <RichText document={doc()} />}</Show>
											</article>
										)}
									</For>
								</div>
							</Section>
						</Page>
					</Container>

					<Container>
						<HorizontalRule />
					</Container>

					<Container size="small">
						<Section>
							<Heading as="h2">Diversity, Equity, and Inclusion Council</Heading>
							<p>
								In late 2020, Troon established the Diversity, Equity, and Inclusion (DEI) Council with the mission of
								fostering a company-wide culture where all associates feel valued, included, and empowered to bring
								their authentic selves to work each day. At Troon, we believe that talent—defined by natural abilities,
								character, and work ethic—should be the only meaningful differentiator in professional growth and
								opportunity. Promoting diversity, equity, and inclusion is not only the right thing to do—it is also a
								strategic advantage. A diverse and inclusive workforce enhances innovation, strengthens collaboration,
								and drives overall business success. By cultivating an open environment, Troon ensures that existing
								associates are more engaged and productive, recruits top-tier talent from the broadest possible pool,
								and incorporates a range of perspectives to address challenges faced by the company, its clients, and
								its associates.
							</p>

							<Heading as="h3">Areas of focus</Heading>
							<ul class="flex list-disc flex-col gap-2 ps-6">
								<li>Communication</li>
								<li>Learning & Development</li>
								<li>Sponsorship & Partnerships</li>
								<li>Community & Engagement</li>
								<li>Employment, Recruitment, Retention</li>
							</ul>
						</Section>
					</Container>

					<Container>
						<HorizontalRule />
					</Container>

					<Container>
						<PictureSection
							reverse
							src="https://images.ctfassets.net/rdsy7xf5c8dt/5sv1OpU1JTqwX1jwlIlVWx/51e6a145beb9bfbba074f983f4ba1235/Two_women_at_work.jpg?h=250"
						>
							<Heading as="h2">Supplier Diversification Program</Heading>
							<p>
								At Troon, we are committed to fostering strong partnerships that drive innovation and deliver
								exceptional value to our clients. As a global leader in golf and club-related hospitality services, we
								recognize the benefits of working with a broad network of suppliers who bring unique perspectives,
								expertise, and solutions to our industry. Our Supplier Diversification Program provides opportunities
								for businesses of all backgrounds to contribute to our product and service offerings. To be considered
								for our program, please complete the form below. Your application will be reviewed by our procurement
								team. Thank you for your interest in partnering with Troon.
							</p>
							<p>
								<Button as={Link} href="/about/dei/supplier-diversification-initiative" class="size-fit">
									Apply now
								</Button>
							</p>
						</PictureSection>
					</Container>
				</Page>
			</NoHydration>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const getThemes = createContentfulListRequest('dei-themes');
