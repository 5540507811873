import { mount, StartClient } from '@solidjs/start/client';
import { AnalyticsProvider } from '@troon/analytics';
import { createClient } from '@troon/analytics/client';
import { getUserAgent, isPerformanceRestrictedUserAgent } from '@troon/user-agent';
import { createApiClient } from '@troon/api-client';
import { ErrorBoundary } from './components/error-boundary';
import { getConfigValue } from './modules/config';
import { initSentry } from './modules/sentry/browser';
import { polyfill } from './providers/polyfill';

// Polyfill for Object.hasOwn – we get hit by lots of old bots that need this
if (!Object.hasOwn) {
	Object.defineProperty(Object, 'hasOwn', {
		value: function (obj: Record<string, unknown>, prop: string) {
			return Object.prototype.hasOwnProperty.call(obj, prop);
		},
		configurable: true,
		enumerable: false,
		writable: true,
	});
}

window.addEventListener('vite:preloadError', () => {
	window.location.reload(); // for example, refresh the page
});

createApiClient({
	baseUrl: getConfigValue('API_URL'),
});

async function startup() {
	await polyfill();
	const ua = getUserAgent();
	const isDisallowedClient = isPerformanceRestrictedUserAgent(ua);

	const posthog =
		!isDisallowedClient && getConfigValue('POSTHOG_ENABLED')
			? createClient({
					key: getConfigValue('POSTHOG_WRITE_KEY'),
					api_host: `https://${getConfigValue('HOST')}/_/ingest`,
					bootstrap: {
						// If not logged in, use session ID & ensure isIdentifiedID is false
						distinctID: window.__USER__?.me.id ?? window.__SESSIONID__ ?? undefined,
						isIdentifiedID: !!window.__USER__?.me.id,
						featureFlags: window.__FLAGS__,
					},
				})
			: undefined;

	if (!isDisallowedClient && getConfigValue('SENTRY_ENABLED')) {
		requestIdleCallback(() => {
			initSentry(posthog);
		});
	}

	mount(() => {
		return (
			<ErrorBoundary>
				<AnalyticsProvider
					gtmId={isDisallowedClient || window.__CTX__?.performanceRestricted ? undefined : getConfigValue('PIXEL_GTM')}
					posthog={posthog}
					flags={window.__FLAGS__}
					overrides={window.__FLAG_OVERRIDES__}
				>
					<StartClient />
				</AnalyticsProvider>
			</ErrorBoundary>
		);
	}, document.getElementById('app')!);
}

startup();
