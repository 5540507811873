import { Container, Heading, Section } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { Meta } from '@solidjs/meta';
import { getConfigValue } from '../../modules/config';
import { getContentSecurityPolicy } from '../../modules/content-security-policy';

export default function DownForMaintenance() {
	return (
		<NoHydration>
			<Meta http-equiv="content-security-policy" content={getContentSecurityPolicy()} />
			<Container class="flex h-full grow flex-col items-center justify-center gap-24 text-center">
				<img src={`${getConfigValue('IMAGE_HOST')}/web/photo/maintenance.png`} alt="" class="w-96" />
				<Section>
					<Heading as="h2" size="h1">
						We’ll be back soon!
					</Heading>
					<p>We are very sorry for the inconvenience but we are performing maintenance. Please check back soon. </p>
				</Section>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { nav: { appearance: 'extra-minimal' }, banner: { hide: true } } };
