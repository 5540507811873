import {
	ActivityIndicator,
	Button,
	Container,
	Heading,
	Link,
	MessageBar,
	Page,
	Section,
	SectionStepHeading,
	TextLink,
} from '@troon/ui';
import { createEffect, createSignal, ErrorBoundary, Match, onCleanup, onMount, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { useAction, useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { createStore } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import { IconCircleWarning } from '@troon/icons/circle-warning';
import { Title } from '@solidjs/meta';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { Grid, GridFive, GridSeven } from '../../../../components/layouts/grid';
import { useUser } from '../../../../providers/user';
import { AuthFlow } from '../../../../partials/auth/auth';
import { logout } from '../../../auth/components/logout-action';
import { getConfigValue } from '../../../../modules/config';
import { NotFoundContent } from '../../../../partials/404';
import { authHeaders } from '../../../../partials/auth/inline';
import { logos, rewardLevel, slugToProgram, title, VisaBenefits } from './_content';
import type { ProgramType } from './_content';
import type { ApiResponse } from '@troon/api-client';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function VerifyVisaRewards(props: RouteSectionProps) {
	const user = useUser();
	const trackEvent = useTrackEvent();

	// eslint-disable-next-line solid/reactivity
	const handleLogout = logout(trackEvent, `/rewards/visa/verify${props.params.cardType ?? ''}`);

	const getVisaIframeToken = useAction(postGetVisaIframeToken.with(new FormData()));
	const submission = useSubmission(postGetVisaIframeToken);
	const [verification, setVerification] = createStore<Partial<ApiResponse<'get', '/v0/visa/tokens/{token}'>>>({});

	const [programType, setProgramType] = createSignal<ProgramType>(
		// eslint-disable-next-line solid/reactivity
		slugToProgram[props.params.program! as keyof typeof slugToProgram]!,
	);

	createEffect(() => {
		if (user()?.me && !submission.result?.token && !submission.pending && !submission.error) {
			getVisaIframeToken();
		}
	});

	createEffect(() => {
		if (verification.state) {
			trackEvent('visaRewardsVerification', { visaVerificationState: verification.state });
		}
	});

	function listener(e: MessageEvent) {
		if (import.meta.env.NODE_ENV === 'test' || e.origin === `${window.location.protocol}//${window.location.host}`) {
			try {
				const response = JSON.parse(e.data) as ApiResponse<'get', '/v0/visa/tokens/{token}'>;
				setVerification(response);
				const program = response.visaStatus?.programType;
				if (program) {
					setProgramType(program);
				}
			} catch {
				// no-op
			}
		}
	}

	onMount(() => {
		if (!isServer) {
			window.addEventListener('message', listener);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			window.removeEventListener('message', listener);
		}
	});

	return (
		<Show when={programType()} fallback={<NotFoundContent />}>
			<Title>Verify your {title[programType()]} Benefits | Troon Rewards | Troon</Title>
			<div class="h-screen grow bg-neutral-100">
				<Container>
					<Page>
						<Switch>
							<Match when={verification.state !== 'VERIFIED'}>
								<Grid>
									<GridSeven class="order-2 flex flex-col gap-8 md:order-1">
										<Heading as="h1">Verify your {title[programType()]} Benefits</Heading>
										<Section appearance="contained">
											<Show
												when={user()}
												fallback={
													<div class="flex flex-col gap-4">
														<SectionStepHeading step={1} state="current">
															Log in or sign up
														</SectionStepHeading>
														<AuthFlow inline headers={authHeaders} showSteps={false} />
													</div>
												}
											>
												{(user) => (
													<>
														<SectionStepHeading
															step={1}
															state="completed"
															action={
																<TextLink
																	href="/auth/logout"
																	onClick={(e) => {
																		e.preventDefault();
																		handleLogout();
																	}}
																>
																	Log out
																</TextLink>
															}
														>
															Account information
														</SectionStepHeading>

														<ul class="flex flex-col gap-1">
															<li>
																{user().me.firstName} {user().me.lastName}
															</li>
															<li>Email address: {user().me.email}</li>
															<li>Troon Rewards #{user().me.troonRewardsId}</li>
														</ul>
													</>
												)}
											</Show>
										</Section>

										<Section appearance="contained">
											<SectionStepHeading
												step={2}
												state={verification.state === 'VERIFIED' ? 'completed' : user() ? 'current' : 'waiting'}
											>
												Verify your Visa Card
											</SectionStepHeading>
											<Show when={user() && verification.state !== 'VERIFIED'}>
												<p>
													Enter your card number to verify and authenticate your elegibility for Troon Rewards benefits.
												</p>
												<ErrorBoundary
													fallback={(error) => {
														return (
															<Show when={error.statusCode !== 401}>
																<MessageBar>{error.displayMessage ?? error.message ?? error.toString()}</MessageBar>
															</Show>
														);
													}}
												>
													<Suspense fallback={<ActivityIndicator />}>
														<Switch fallback={<ActivityIndicator />}>
															<Match when={verification.state === 'FAILED'}>
																<MessageBar appearance="danger" icon={IconCircleWarning}>
																	Your card is not able to be verified at this time.
																</MessageBar>
															</Match>
															<Match when={submission.result?.token}>
																{(token) => (
																	<iframe
																		data-testId="iframe"
																		tabIndex={-1}
																		class="w-full"
																		src={`${iframeUrls[getConfigValue('ENVIRONMENT') ?? 'production']}?Token=${token()}`}
																	/>
																)}
															</Match>
														</Switch>
													</Suspense>
												</ErrorBoundary>
											</Show>
										</Section>
									</GridSeven>

									<GridFive class="order-1 md:order-2">
										<div class="flex flex-col gap-8 md:mt-24">
											<img
												src={logos[programType()]}
												alt={title[programType()]}
												width={96}
												height={42}
												class="mx-auto w-full max-w-64"
											/>

											<VisaBenefits program={programType()} />

											<p>
												<TextLink href={`/rewards/visa/${props.params.program}/terms`}>Terms and Conditions</TextLink>
											</p>
										</div>
									</GridFive>
								</Grid>
							</Match>
							<Match when={verification.state === 'VERIFIED'}>
								<div class="flex flex-col gap-8">
									<Container class="max-w-screen-md">
										<div class="flex flex-col items-center gap-6 text-center">
											<IconCircleCheckCustom class="text-8xl" />
											<Heading as="h1" class="leading-snug md:leading-snug">
												Your {title[verification.visaStatus!.programType]} card has been verified!
											</Heading>
											<p class="text-lg">
												You now have <b>{rewardLevel[verification.newRewardsLevel!]}</b> Troon Rewards status.
											</p>
											<Button as={Link} href="/tee-times">
												Find a Tee Time
											</Button>
										</div>
									</Container>
									<Container size="xsmall">
										<Section appearance="contained">
											<Heading as="h2" size="h4">
												Summary
											</Heading>

											<div class="divide-y divide-neutral">
												<p class="flex justify-between py-4">
													<span>Previous Troon Rewards® Level</span>
													<span>{rewardLevel[verification.originalRewardsLevel!]}</span>
												</p>
												<p class="flex justify-between py-4">
													<b>New Troon Rewards® Level</b>
													<b>{rewardLevel[verification.newRewardsLevel!]}</b>
												</p>
											</div>

											<VisaBenefits
												program={programType()}
												privateEligible={verification.visaStatus?.privateEligible}
											/>
										</Section>
										<p class="text-center text-sm">
											<TextLink href={`/rewards/visa/${props.params.program}/terms`}>Terms and Conditions</TextLink>
										</p>
									</Container>
								</div>
							</Match>
						</Switch>
					</Page>
				</Container>
			</div>
		</Show>
	);
}

export const route = {
	info: { nav: { appearance: 'extra-minimal' }, banner: { hide: true } },
} satisfies RouteDefinition;

const postGetVisaIframeToken = clientAction('POST', '/v0/visa/tokens', {});

const iframeUrls: Record<Required<Window['__TROON_CONFIG__']>['ENVIRONMENT'], string> = {
	development: 'https://vcescert.visammg.com/VCESIFrame/',
	staging: 'https://vcescert.visammg.com/VCESIFrame/',
	production: 'https://www.visammg.com/VCESIFrame/',
};
