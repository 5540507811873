import {
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Link,
	LinkButton,
	Section,
	Separator,
	Container,
	Page,
} from '@troon/ui';
import { cachedGet, revalidate } from '@troon/api-client';
import { Match, Show, Suspense, Switch } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { useUser } from '../../providers/user';
import { AuthFlow } from '../../partials/auth/auth';
import { Hero } from '../../components/hero/photo';
import { Grid, GridSeven, GridFive } from '../../components/layouts/grid';
import { getConfigValue } from '../../modules/config';
import { title, VisaBenefits } from './visa(confirmation)/verify/_content';
import type { RouteDefinition } from '@solidjs/router';

export default function VisaInfinitePage() {
	const user = useUser();
	const status = createAsync(() => getVisaStatus({}), { deferStream: true });
	return (
		<>
			<Title>Visa Golf Benefits | Troon Rewards | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/member-programs.jpg`}>
				<Heading as="h1">Visa Golf Benefits</Heading>
				<p>by Troon Rewards®</p>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridSeven>
							<Section>
								<Heading as="h2">Enjoy Premium Benefits</Heading>
								<p>
									Qualifying Visa cards entitle you to automatic upgraded status with Troon Rewards®, savings on tee
									time fees, merchandise, and lessons at over 150 golf courses across the United States and world.
								</p>
								<p>
									Visa Infinite® US and Visa Infinite Privilege® CA cards also gives access to over 20 high-end,
									private Troon Privé golf clubs. Play each club two times per year for $99 per player per round when
									you use your Visa Infinite card to book, and bring up to three guests at the same rate.
								</p>
							</Section>
						</GridSeven>
						<GridFive>
							<Section appearance="contained" class="bg-neutral-100">
								<Suspense>
									<Switch>
										<Match when={status()?.data}>
											{(program) => (
												<>
													<Heading as="h2" size="h4">
														Your Benefits
													</Heading>

													<p>Your {title[program().programType]} card is verified and benefits have been activated!</p>

													<VisaBenefits program={program().programType} privateEligible={program().privateEligible} />

													<Show when={program().canUpgrade}>
														<HorizontalRule />
														<p>Have another qualifying Visa card? Verify it now to redeem your benefits.</p>
														<Button as={Link} href="/rewards/visa/verify/visa-infinite" appearance="tertiary">
															Verify your card
														</Button>
													</Show>
												</>
											)}
										</Match>
										<Match when={!status()?.data?.privateEligible}>
											<Heading as="h2" size="h4">
												Redeem Your Visa Benefits
											</Heading>

											<Show when={!user()}>
												<p>
													Already a Troon Rewards member?{' '}
													<Dialog key="auth">
														<DialogTrigger as={LinkButton} href="/auth" class="-mx-2">
															Log in now
														</DialogTrigger>
														<DialogContent width="lg" noPadding closeButton="text-white">
															<AuthFlow
																onComplete={() => {
																	revalidate('/v0/visa/status');
																}}
															/>
														</DialogContent>
													</Dialog>{' '}
													to see your benefits.
												</p>
												<Separator>OR</Separator>
											</Show>

											<p>Select your qualifying Visa card to redeem your benefits now.</p>

											<Heading as="h3" size="h5">
												US Cardholders
											</Heading>

											<Button as={Link} href="/rewards/visa/verify/visa-signature" appearance="tertiary">
												Visa Signature®
											</Button>
											<Button as={Link} href="/rewards/visa/verify/visa-infinite" appearance="tertiary">
												Visa Infinite®
											</Button>

											<Heading as="h3" size="h5">
												Canadian Cardholders
											</Heading>
											<Button as={Link} href="/rewards/visa/verify/visa-infinite-ca" appearance="tertiary">
												Visa Infinite®
											</Button>
											<Button as={Link} href="/rewards/visa/verify/visa-infinite-privilege-ca" appearance="tertiary">
												Visa Infinite Privilege®
											</Button>
										</Match>
									</Switch>
								</Suspense>
							</Section>
						</GridFive>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const getVisaStatus = cachedGet('/v0/visa/status', {}, { redirect401: false });
