import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Button, Link, Container, Page, Section, Heading, HorizontalRule } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { IconCalendar } from '@troon/icons/calendar';
import { gql, ReservationState } from '../graphql';
import { ReservationCard } from '../components/reservation-card';
import { PageHeader } from '../components/page-header';
import { cachedQuery } from '../graphql/cached-get';
import { dayTimeToDate } from '../modules/date-formatting';
import type { RouteDefinition } from '@solidjs/router';

export default function Reservations() {
	const data = createAsync(() => getReservations({}), { deferStream: true });
	return (
		<Container>
			<Page>
				<Title>Reservations | Troon</Title>

				<Section>
					<PageHeader title={<>Reservations</>} subtext={<p>View and manage all of your tee times.</p>} />
					<HorizontalRule />

					<Heading as="h2" size="h4">
						Upcoming
					</Heading>
					<ul class="flex flex-col gap-y-8">
						<Suspense>
							<Show when={data()}>
								<For
									each={data()?.reservations.filter(
										(res) =>
											(res.state === ReservationState.Active || res.state === ReservationState.Pending) &&
											dayTimeToDate(res.dayTime).valueOf() > Date.now(),
									)}
									fallback={
										<li class="flex flex-col items-center gap-6 rounded border border-neutral p-4 md:p-8">
											<IconCalendar class="size-20 text-brand md:size-24" />
											<p class="flex flex-col text-center">
												<span class="text-xl font-semibold">You have no upcoming reservations.</span>
												<span>When you book a tee time, you’ll find it here.</span>
											</p>
											<Button as={Link} href="/" appearance="secondary">
												Book a Tee Time
											</Button>
										</li>
									}
								>
									{(res) => (
										<li>
											<ReservationCard reservation={res} />
										</li>
									)}
								</For>
							</Show>
						</Suspense>
					</ul>
				</Section>

				<Suspense>
					<Show
						when={data()?.reservations.filter(
							(res) =>
								(res.state !== ReservationState.Active && res.state !== ReservationState.Pending) ||
								dayTimeToDate(res.dayTime).valueOf() < Date.now(),
						)}
					>
						{(pastReservations) => (
							<Section>
								<Heading as="h2" size="h4">
									Past
								</Heading>
								<ul class="flex flex-col gap-y-8">
									<For each={pastReservations()} fallback={<li>No previous reservations</li>}>
										{(res) => (
											<li>
												<ReservationCard reservation={res} />
											</li>
										)}
									</For>
								</ul>
							</Section>
						)}
					</Show>
				</Suspense>
			</Page>
		</Container>
	);
}

export const route = {
	preload: () => getReservations({}),
} satisfies RouteDefinition;

const reservationsQuery = gql(`
query allReservations {
	reservations: allReservations(sortOrder: DESC) {
		...ReservationCard
		state
		dayTime { day { year, month, day }, time { hour, minute } }
	}
}`);

const getReservations = cachedQuery(reservationsQuery);
