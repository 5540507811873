import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconPrive(props: IconProps) {
	return (
		<Icon {...props}>
			<path d="M.1 12v12h24V0H.1v12Zm22.3 0v10.2H1.8V1.7h20.6V12Z" fill="currentColor" />
			<path d="M9.7 7.6v.7H11.4V17H13V8.3H14.7V7h-5v.7Z" fill="currentColor" />
		</Icon>
	);
}
