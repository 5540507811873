import { Heading } from '@troon/ui';
import { Video } from '@troon/video';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function MunicipalServicesPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Troon Provides Solutions and Delivers Results for Municipalities</Heading>

				<ul class="flex list-disc flex-col gap-2 ps-8">
					<li>
						Turnkey management services for 150+ locations, providing support for golf operations, agronomy, marketing,
						food and beverage, accounting, human resources, and more
					</li>
					<li>
						Flexible structures based on your situation and facility needs, to include Traditional Management
						Services(fee for service), Lease Structure, or a Hybrid Structure
					</li>
					<li>Capital Investment to ensure long term sustainability and enhanced offerings for your facility</li>
					<li>Focus on making the golf facility a community engagement catalyst</li>
					<li>Robust marketing and analytic programs to maximize revenues and improve the bottom line</li>
					<li>
						Training solutions designed to deliver exceptional customer experiences and professional development tracks
						to prepare future leaders
					</li>
					<li>
						Economies of scale in procurement, effective systems, and processes create efficiency and savings within
						every department
					</li>
					<li>
						Environmental Stewardship and Commitment with a total of 70+ Audubon Certified Golf Courses within Troon
					</li>
					<li>
						Consulting Services to include prioritization of capital projects, operational review, and benchmarking
						studies
					</li>
				</ul>
			</NoHydration>

			<Video
				src={`${getConfigValue('CDN')}/web/video/management-services/municipal/index.m3u8`}
				poster={`${getConfigValue('IMAGE_HOST')}/web/video/management-services/municipal/poster.jpg`}
			/>
		</>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		hero: `${getConfigValue('IMAGE_HOST')}/web/hero/municipal.jpg`,
		title: 'Municipal Properties',
		description: 'Improve your course conditions and reduce your costs.',
	},
} satisfies RouteDefinition;
