import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Container, Heading, HorizontalRule, Page, Section } from '@troon/ui';
import { useLocation, createAsync } from '@solidjs/router';
import { Title, Meta } from '@solidjs/meta';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { VideoSection } from '../../components/layouts/video-section';

import { cachedQuery } from '../../graphql/cached-get';

import { gql } from '../../graphql';
import type { RouteDefinition } from '@solidjs/router';

export default function AdvantagePage() {
	const loc = useLocation();
	const compare = new Intl.Collator('en').compare;

	const facilitiesData = createAsync(
		async () => {
			const data = await getFacilities({});
			const facilities = data?.facilitiesV3?.facilities || [];
			const byState = new Map<string, Array<{ name: string; city: string }>>();
			const byCountry = new Map<string, Array<{ name: string; city: string }>>();

			facilities.forEach((facility) => {
				const country = facility.metadata?.address?.country;
				const state = facility.metadata?.address?.state;
				const city = facility.metadata?.address?.city;

				if ((country === 'US' || country === 'United States') && state) {
					// Domestic facilities
					if (!byState.has(state)) {
						byState.set(state, []);
					}
					byState.get(state)?.push({
						name: facility.name,
						city: city || '',
					});
				} else if (country) {
					// International facilities
					if (!byCountry.has(country)) {
						byCountry.set(country, []);
					}
					byCountry.get(country)?.push({
						name: facility.name,
						city: city || '',
					});
				}
			});

			return {
				domesticFacilities: Array.from(byState.entries())
					.map(([location, facilities]) => ({
						location,
						facilities: facilities.sort((a, b) => compare(a.name, b.name)),
					}))
					.sort((a, b) => compare(a.location, b.location)),
				internationalFacilities: Array.from(byCountry.entries())
					.map(([location, facilities]) => ({
						location,
						facilities: facilities.sort((a, b) => compare(a.name, b.name)),
					}))
					.sort((a, b) => compare(a.location, b.location)),
			};
		},
		{ deferStream: true },
	);

	const domesticFacilities = () => facilitiesData()?.domesticFacilities || [];
	const internationalFacilities = () => facilitiesData()?.internationalFacilities || [];

	return (
		<>
			<Title>Troon Advantage | Troon Privé Member Programs | Troon</Title>
			<Meta
				name="description"
				content="Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves and up to three guests."
			/>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/web/hero/advantage.jpeg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Troon Advantage</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Troon Advantage</Heading>
					<p class="text-lg">
						Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves and
						up to three guests.
					</p>
				</Hero>

				<Container>
					<Page>
						<VideoSection
							src={`${getConfigValue('CDN')}/web/video/troon-advantage/index.m3u8`}
							poster={`${getConfigValue('IMAGE_HOST')}/web/video/troon-advantage/poster.jpg`}
						>
							<Heading as="h2">Overview</Heading>
							<p>
								Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves
								and up to three guests. Reservations can be made up to sixty (60) days in advance and are subject to
								availability. To book a tee time through Troon Advantage:
							</p>
							<ul class="flex list-disc flex-col gap-4 ps-4">
								<li>
									Access the new Troon Advantage booking link that has been provided to qualified members by the home
									clubs. The location of this live link must be provided by your home club and is not available from the
									host club, on Troon.com or through Troon corporate. Please do not call the host club to book a tee
									time or ask for the link.
								</li>

								<li>
									The new booking link features a searchable database of more than 200 Troon-affiliated resort and daily
									fee destinations around the world, but please feel free to reference the list below for inspiration!
									The contact form previously accessed by clicking Request a Tee Time has been removed, as resort/daily
									fee tee times can only now be booked via the link provided by your home club.
								</li>
							</ul>
						</VideoSection>

						<HorizontalRule />

						<Section>
							<Heading as="h2" class="text-center">
								Domestic locations
							</Heading>
							<ul class="list-disc gap-16 ps-6 sm:columns-2 md:gap-24 lg:columns-3">
								<For each={domesticFacilities()}>
									{(state) => (
										<li class="mb-4 break-inside-avoid-column">
											<strong>{state.location}</strong>
											<ul class="flex list-disc flex-col gap-1 ps-6">
												<For each={state.facilities}>
													{(facility) => (
														<li>
															{facility.name} | {facility.city}
														</li>
													)}
												</For>
											</ul>
										</li>
									)}
								</For>
							</ul>
						</Section>

						<HorizontalRule />

						<Section>
							<Heading as="h2" class="text-center">
								International locations
							</Heading>
							<ul class="list-disc gap-16 ps-6 sm:columns-2 md:gap-24 lg:columns-3">
								<For each={internationalFacilities()}>
									{(country) => (
										<li class="mb-4 break-inside-avoid-column">
											<strong>{country.location}</strong>
											<ul class="flex list-disc flex-col gap-1 ps-6">
												<For each={country.facilities}>
													{(facility) => (
														<li>
															{facility.name} | {facility.city}
														</li>
													)}
												</For>
											</ul>
										</li>
									)}
								</For>
							</ul>
						</Section>
					</Page>
				</Container>
			</NoHydration>
		</>
	);
}

const query = gql(`query troonAdvantageFacilities {
	facilitiesV3(supportsTroonAdvantage: true) {
		facilities {
			name
			metadata {
				address {
					country
					city
					state
				}
			}
		}
	}
}`);

const getFacilities = cachedQuery(query);

export const route = {
	info: {
		nav: {
			hero: true,
		},
	},
	preload: () => getFacilities({}),
} satisfies RouteDefinition;
