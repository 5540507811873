import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconHamburgerMd(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M5 17h14M5 12h14M5 7h14"
			/>
		</Icon>
	);
}
