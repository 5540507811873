import { createAsync, useCurrentMatches } from '@solidjs/router';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useTrackEvent } from '@troon/analytics';
import { createContentfulRequest } from '../routes/content/[model]/_client';
import { useUser } from '../providers/user';
import { RichText } from './rich-text';

export function SiteBanner() {
	const matches = useCurrentMatches();
	const track = useTrackEvent();
	const user = useUser();

	const banner = createAsync(
		async () => {
			const hideBanner = matches().some((match) => !!match.route.info?.banner?.hide);
			if (hideBanner) {
				return undefined;
			}
			const slug =
				matches().find((match) => match.route.info?.banner)?.route.info?.banner?.slug || 'troon-access-upsell';

			if ((user()?.me.troonAccessProductType && slug.includes('access')) || !slug) {
				return undefined;
			}

			try {
				const banner = await getBanner(slug);
				return { slug, banner };
			} catch {
				return undefined;
			}
		},
		{ deferStream: true },
	);

	return (
		<ErrorBoundary fallback={null}>
			<Suspense>
				<Show when={banner.latest?.banner}>
					{(data) => (
						<div
							onClick={() => {
								track('siteBannerClicked', { siteBanner: banner.latest?.slug });
							}}
							class={twJoin(
								'relative z-40 flex flex-row flex-wrap items-center justify-center gap-1 px-4 py-2 text-center text-sm [&_a]:text-nowrap [&_a]:underline [&_a]:before:absolute [&_a]:before:inset-0',
								data().fields.backgroundStyle === 'Access' &&
									'bg-gradient-to-r from-neutral-950 to-brand-700 text-white [&_a]:text-white',
								data().fields.backgroundStyle === 'Neutral' && 'bg-neutral-100',
								data().fields.backgroundStyle === 'Brand' && 'bg-brand-100 text-brand-600',
							)}
						>
							<RichText document={data().fields.content} />
						</div>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}

const getBanner = createContentfulRequest('site-banner');
