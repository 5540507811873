import { Router } from '@solidjs/router';
import { FileRoutes } from '@solidjs/start/router';
import { MetaProvider } from '@solidjs/meta';
import { useTrackEvent, useTrackPageview } from '@troon/analytics';
import { UIProvider } from '@troon/ui';
import './app.css';
import { Suspense } from 'solid-js';
import { withSentryRouterRouting } from '@sentry/solidstart/solidrouter';
import { ErrorBoundary } from './components/error-boundary';
import { UserContext } from './providers/user';
import { ServiceWorker } from './providers/service-worker';
import { SkipToMain } from './components/skip-to-main';
import { UtmProvider } from './providers/utm';
import { getBaseUrl } from './modules/schema/base-url';
import { GenericLayout } from './layouts/generic';
import { createVisibilityReloader } from './providers/visibility';
import { PersistenceProvider } from './providers/persistence-store';

const SentryRouter = withSentryRouterRouting(Router);

export default function App() {
	const track = useTrackEvent();

	return (
		<SentryRouter
			explicitLinks
			preload
			root={(props) => (
				<PersistenceProvider>
					<UtmProvider>
						<ServiceWorker>
							<MetaProvider>
								<UIProvider
									baseUrl={getBaseUrl()}
									onDialogOpened={(key) => track('dialogOpened', { key }, { noDuplicates: true })}
									onDialogClosed={(key) => track('dialogClosed', { key }, { noDuplicates: true })}
								>
									<UserContext>
										<Suspense>
											<Tracker />
											<SkipToMain id="content" />
											<ErrorBoundary>
												<Suspense>
													<GenericLayout>{props.children}</GenericLayout>
												</Suspense>
											</ErrorBoundary>
										</Suspense>
									</UserContext>
								</UIProvider>
							</MetaProvider>
						</ServiceWorker>
					</UtmProvider>
				</PersistenceProvider>
			)}
		>
			<FileRoutes />
		</SentryRouter>
	);
}

function Tracker() {
	createVisibilityReloader();
	useTrackPageview();
	return null;
}
