import { For, Show, Suspense } from 'solid-js';
import { Link, Tab, TabList, TabPanel, Tabs, ActivityIndicator } from '@troon/ui';
import { createAsync, useNavigate } from '@solidjs/router';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { FacilitiesByGrouping } from '../../../components/facility/by-grouping';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function CourseRegions(props: RouteSectionProps) {
	const data = createAsync(() => getFacilities({ macroRegionIds: [props.params.region || 'americas'] }), {
		deferStream: true,
	});
	const navigate = useNavigate();

	return (
		<Show when={data.latest}>
			<Tabs
				value={props.params.region || 'americas'}
				defaultValue={props.params.region || 'americas'}
				class="flex flex-col gap-10"
			>
				<TabList aria-label="Course regions">
					<For each={data.latest?.regions}>
						{(region) => (
							<Tab
								value={region.id}
								as={Link}
								replace
								href={`/courses/${region.id}`}
								onClick={(e) => {
									e.preventDefault();
									navigate(`/courses/${region.id}`, { scroll: false, replace: true });
								}}
							>
								{region.name}
							</Tab>
						)}
					</For>
				</TabList>
				<For each={data.latest?.regions}>
					{(region) => (
						<TabPanel value={region.id}>
							<Suspense fallback={<ActivityIndicator />}>
								<FacilitiesByGrouping
									facilities={data()?.facilities.facilities ?? []}
									grouping={region.id === 'americas' ? 'state' : 'country'}
								/>
							</Suspense>
						</TabPanel>
					)}
				</For>
			</Tabs>
		</Show>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const query = gql(`query courseDirectory($macroRegionIds: [String!]!) {
	regions: macroRegions {
		id
		name
	}
	facilities: facilitiesV3(macroRegionIds: $macroRegionIds, sortBy: name) {
		facilities {
			...FacilityByState
		}
	}
}`);

const getFacilities = cachedQuery(query);
